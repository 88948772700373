import { inject, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class Toast {
    private _toaster = inject(ToastrService);

    /**
     * Удачный ответ
     *
     * @param {string} text
     * @param {number} timeOut
     */
    success(text: string, timeOut: number = 5000): void {
        this._toaster.success(text, '', { timeOut: timeOut });
    }

    /**
     * Ошибка
     *
     * @param {string} text
     * @param {number} timeOut
     */
    error(text: string = 'Произошла ошибка', timeOut: number = 10000): void {
        this._toaster.error(text, '', { timeOut: timeOut });
    }

    /**
     * Предупреждение
     *
     * @param {string} text
     * @param {number} timeOut
     */
    warning(text: string, timeOut: number = 5000): void {
        this._toaster.warning(text, '', { timeOut: timeOut });
    }

    /**
     * Информация
     *
     * @param {string} text
     * @param {number} timeOut
     */
    info(text: string, timeOut: number = 5000): void {
        this._toaster.info(text, '', { timeOut: timeOut });
    }
}
